import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useLatestOrderData(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch, onResult } = useQuery(LATEST_ORDER_DATA, variables, () => ({
    enabled: variables.value.initialRender,
  }));
  const orderItems = computed(() =>
    result.value && result.value.orderItemsNew
      ? result.value.orderItemsNew.nodes.map((order) => ({
          ...order,
          date: new Date(order.date),
        })) ?? []
      : []
  );

  onError((err) => {
    console.error('useLatestOrderData', err);
    error();
  });

  return {
    orderItems,
    loading,
    refetch,
    onResult,
  };
}

const LATEST_ORDER_DATA = gql`
  query latestOrderData($businessId: ID!, $productId: ID, $limit: Int, $sortBy: String) {
    orderItemsNew(businessId: $businessId, productId: $productId, limit: $limit, sortBy: $sortBy) {
      nodes {
        id
        productId
        netAmountPerUnit
        orderDate
        quantity
      }
    }
  }
`;
