import { computed } from 'vue';

import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

export function useSupplierForOrderSummary(variables) {
  const { result, loading, onError, refetch } = useQuery(BUSINESS_BY_ID, variables, () => ({
    enabled: !!variables.value.businessId,
  }));

  const business = computed(() => {
    return result.value ?? { getBusinessById: {} };
  });

  return {
    business,
    loading,
    refetch,
    onError,
  };
}

const BUSINESS_BY_ID = gql`
  query supplierForOrderSummary($businessId: ID!) {
    getBusinessById(businessId: $businessId) {
      name
      contact {
        address
      }
    }
  }
`;
