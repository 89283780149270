import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { getCurrentInstance } from 'vue';
import { useNotification } from '@/modules/core/compositions/notification';
import { useTranslate } from '@/modules/core/compositions/translate';

export const useDeleteAllOrderItems = () => {
  const { $message } = getCurrentInstance().proxy;
  const $t = useTranslate();
  const { error } = useNotification();

  const { mutate, onError, loading, onDone } = useMutation(DELETE_ORDER_PROCESS_ITEM);

  onError((err) => {
    const errorBody = err?.graphQLErrors[0]?.extensions?.response?.body;
    if (errorBody && errorBody.details && errorBody.details.outdated === true) {
      $message.error({ message: $t('orderProcessCart.orderNotUpToDate'), duration: 5000 });
    } else {
      error();
      console.error('orderProcessCart - useDeleteAllOrderItems', err);
    }
  });

  return {
    deleteAllOrderItems: mutate,
    onError,
    loading,
    onDone,
  };
};

const DELETE_ORDER_PROCESS_ITEM = gql`
  mutation deleteAllOrderProcessItems($orderId: ID, $updatedAt: Timestamp) {
    deleteAllOrderProcessItems(orderId: $orderId, updatedAt: $updatedAt) {
      updatedAt
    }
  }
`;
