import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useSupplierRestockProductsList({ currentTenant, supplierId }) {
  const { error } = useNotification();
  const variables = computed(() => ({
    businessId: currentTenant.value.id,
    supplierId: supplierId.value,
    first: 25,
    after: 0,
  }));

  const { result, loading, onError, refetch, onResult } = useQuery(SUPPLIER_RESTOCK_PRODUCTS_LIST, variables, () => ({
    enabled: !!variables.value.businessId && !!variables.value.supplierId,
  }));

  const restockProductsList = computed(() => {
    if (result.value) {
      return result.value.restockProductsLists.nodes[0];
    }

    return undefined;
  });

  onError((err) => {
    console.error('useSupplierRestockProductsList', err);
    error();
  });

  return {
    restockProductsList,
    loading,
    refetch,
    onResult,
  };
}

const SUPPLIER_RESTOCK_PRODUCTS_LIST = gql`
  query supplierRestockProductsList($businessId: ID!, $supplierId: ID, $first: Int, $after: Int) {
    restockProductsLists(businessId: $businessId, supplierId: $supplierId, first: $first, after: $after) {
      nodes {
        id
        businessId
        supplierId
        products {
          productId
          maxStockLevel
        }
      }
    }
  }
`;
