import { ref, watch } from 'vue';

export function useOrderLocalStorage(orderId) {
  const localStorageState = ref(JSON.parse(localStorage.getItem(orderId)) ?? {});

  watch(
    localStorageState,
    () => {
      if (localStorageState.value) localStorage.setItem(orderId, JSON.stringify(localStorageState.value));
    },
    { deep: true }
  );

  const resetOrderLocalStorage = () => {
    localStorageState.value = null;
    localStorage.removeItem(orderId);
  };

  return {
    localStorageState,
    resetOrderLocalStorage,
  };
}
