import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core/compositions/notification';
import { getCurrentInstance } from 'vue';
import { useTranslate } from '@/modules/core/compositions/translate';

export const usePatchOrderProcess = (debounce) => {
  const { $message } = getCurrentInstance().proxy;
  const $t = useTranslate();
  const { error } = useNotification();

  const { mutate, onError, onDone, loading } = useMutation(PATCH_ORDER_PROCESS, () => (debounce ? { debounce } : {}));

  onError((err) => {
    const errorBody = err?.graphQLErrors[0]?.extensions?.response?.body;
    if (errorBody && errorBody.details && errorBody.details.outdated === true) {
      $message.error({ message: $t('orderProcessCart.orderNotUpToDate'), duration: 5000 });
    } else {
      error();
      console.error('orderProcessCart - usePatchOrderProcessItems', err);
    }
  });

  return {
    patchOrderProcess: mutate,
    onError,
    onDone,
    loading,
  };
};

const PATCH_ORDER_PROCESS = gql`
  mutation patchOrderProcess($orderId: ID, $patchPayload: OrderProcessPatchInput, $updatedAt: Timestamp) {
    patchOrderProcessNew(orderId: $orderId, patchPayload: $patchPayload, updatedAt: $updatedAt) {
      updatedAt
    }
  }
`;
