export const SOURCES = {
  CART: 'cart',
  MODAL: 'modal',
};

export const TABLE_HEADERS = {
  NAME: 'name',
  QUANTITY: 'quantity',
  LAST_PRICE: 'lastOrderPrice',
  TOTAL: 'total',
  ACTIONS: 'actions',
  QUANTITY_IN_CART: 'quantityInCart',
  QUANTITY_TO_ADD: 'quantityToAdd',
  MAX_STOCK_LEVEL: 'maxStockLevel',
};
