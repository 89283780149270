import { computed, ref } from 'vue';

import { useCurrency } from '@/locale/useCurrency';
import { useTenancy } from '@/modules/auth';
import { useGetOrdersWithLimit } from './useGetOrdersWithLimit';
import { useOrderProcessById } from '@/modules/orderProcess/compositions/useOrderProcessById';
import { useSupplierPurchaseListItems } from '@/modules/orderProcess/compositions/useSupplierPurchaseListItems';
import { useCatalog } from './useCatalog';
import { formatCurrency } from '@/modules/purchase-management/purchaseManagementFormatters';
import { useFetchAll } from '@/modules/purchase-management/compositions/useFetchAll';

const PURCHASE_LIST_LIMIT = 100;

export function useProcessCartData({ orderId, tenantId, productSearch, findOrderItemForProduct }) {
  const localOrderProcessState = ref({ items: [] });
  const supplierId = ref();
  const supplierName = ref();
  const { currentTenant } = useTenancy();
  const { currencyFormat } = useCurrency();
  const existingOrderNote = ref();
  const canInteractWithOrder = ref(true);
  const updatedAt = ref(null);
  const arrivesAt = ref();
  const openedAt = ref();
  const openedByUser = ref();
  const orderNumber = ref();

  const { loading: ordersLoading, onResult, refetch: refetchOrderProcess } = useOrderProcessById({ orderId });

  onResult(({ data: orderProcess }) => {
    supplierId.value = orderProcess?.orderProcessById?.supplier?.id;
    supplierName.value = orderProcess?.orderProcessById?.supplier?.name;
    existingOrderNote.value = orderProcess?.orderProcessById?.note;
    updatedAt.value = orderProcess?.orderProcessById?.updatedAt;
    openedAt.value = orderProcess?.orderProcessById?.openedAt;
    openedByUser.value = orderProcess?.orderProcessById?.openedByUser;
    arrivesAt.value = orderProcess?.orderProcessById?.arrivesAt
      ? new Date(orderProcess?.orderProcessById?.arrivesAt)
      : null;
    orderNumber.value = orderProcess?.orderProcessById?.orderNumber ?? null;

    localOrderProcessState.value = orderProcess?.orderProcessById || { items: [] };
    if (orderProcess?.orderProcessById?.dispatches) {
      canInteractWithOrder.value = false;
    }
  });

  const params = computed(() => ({
    businessId: tenantId,
    supplierId: supplierId.value,
    limit: 1,
  }));

  const { orders: lastOrderResults } = useGetOrdersWithLimit(params);

  const { allResults: catalogs, isDone: isCatalogsDone } = useFetchAll({
    hook: useCatalog,
    variables: {
      currentTenant,
      supplierId,
    },
    dataSelector: 'catalogs',
    limit: 500,
  });
  const { allResults: purchaseListItems } = useFetchAll({
    hook: useSupplierPurchaseListItems,
    variables: {
      currentTenant,
      supplierId,
    },
    dataSelector: 'purchaseListItems',
    limit: PURCHASE_LIST_LIMIT,
  });

  const productsLoading = computed(() => !isCatalogsDone.value);

  const products = computed(() => {
    const productMap = new Map();

    for (const catalog of catalogs.value) {
      for (const product of catalog.products) {
        if (product) {
          productMap.set(product.id, { ...product, available: true });
        }
      }
    }

    return [...productMap.values()];
  });

  const productsInOpenOrder = computed(() => {
    return localOrderProcessState?.value?.items.map((item) => {
      const orderItem = findOrderItemForProduct({
        ...item,
        quantityState: item.quantityState || item.quantity,
      });
      let available = true;
      if (!productsLoading.value) {
        const product = products.value.find((product) => product.id === item.productId);
        if (!product) available = false;
      }

      return {
        ...orderItem,
        isRecommended: findIsRecommendedFromPurchaseList(item.productId, purchaseListItems.value),
        available,
      };
    });
  });

  const productsInOpenOrderThatArentAvailable = computed(() =>
    productsInOpenOrder.value.filter((product) => product.available === false)
  );

  const lastOrderProducts = computed(() => {
    const [products = { products: [] }] = lastOrderResults?.value?.orders || [];

    const mappedProducts = products?.products
      ?.filter((product) => !product.type)
      ?.map((product) => ({
        id: product.product.id,
        sku: product.product.sku,
        productId: product.productId,
        name: product?.product?.name,
        lastOrderPrice: formatCurrency(product?.price, currencyFormat.value),
      }));

    return mappedProducts;
  });

  const findIsRecommendedFromPurchaseList = (productId, purchaseList) => {
    const purchaseListItem = purchaseList.find(
      ({ productId: purchaseListProductId }) => purchaseListProductId === productId
    );

    return purchaseListItem?.isRecommended;
  };

  const productsFilteredBySearch = computed(() => {
    const productsInOpenOrderSet = new Set();

    productsInOpenOrder.value.forEach((product) => {
      productsInOpenOrderSet.add(product.productId);
    });

    const idsToFilterOut = products?.value?.reduce((productIds, item) => {
      if (item?.associatedProducts?.length) {
        item.associatedProducts.forEach((item) => {
          productIds[item.productId] = true;
        });
      }
      return productIds;
    }, {});

    const productsWithoutIntegrals = products?.value?.filter((item) => !idsToFilterOut[item.id]);

    const productsFilteredBySearch =
      productSearch.value.length === 0
        ? productsWithoutIntegrals
        : (productsWithoutIntegrals || []).filter((item) => item.name.includes(productSearch.value));

    const productsInCatalogSet = {};

    const mappedProducts = productsFilteredBySearch.map((item) => {
      const orderItemProduct = findOrderItemForProduct(item);
      if (orderItemProduct) {
        productsInCatalogSet[item.id] = true;
        return {
          ...orderItemProduct,
          ...item,
          isRecommended: findIsRecommendedFromPurchaseList(item.id, purchaseListItems.value),
          value: item.name,
          disabled: !!productsInOpenOrderSet.has(item.id) || !!productsInOpenOrderSet.has(item.productId),
          available: true,
        };
      }

      productsInCatalogSet[item.productId] = true;
      return {
        ...item,
        isRecommended: findIsRecommendedFromPurchaseList(item.productId, purchaseListItems.value),
        value: item.name,
        disabled: !!productsInOpenOrderSet.has(item.id) || !!productsInOpenOrderSet.has(item.productId),
        available: true,
      };
    });

    for (const purchaseListItem of purchaseListItems.value) {
      if (!productsInCatalogSet[purchaseListItem.productId] && purchaseListItem.isRecommended) {
        const orderItemProduct = findOrderItemForProduct(purchaseListItem.product);
        let itemToAdd = {
          ...purchaseListItem,
          id: purchaseListItem.product.id,
          value: purchaseListItem.product.name,
          disabled: !!productsInOpenOrderSet.has(purchaseListItem.product.id),
          available: false,
        };

        if (orderItemProduct) {
          itemToAdd = { ...orderItemProduct, ...itemToAdd };
        }
        mappedProducts.push(itemToAdd);
      }
    }

    return mappedProducts;
  });

  const changeProductItemQuantity = (productItem) => {
    const itemsClone = [...localOrderProcessState.value.items];
    const productIndex = itemsClone.findIndex((item) => item.id === productItem.id);

    itemsClone[productIndex] = {
      ...itemsClone[productIndex],
      quantity: productItem.quantity,
    };

    localOrderProcessState.value = {
      ...localOrderProcessState.value,
      items: itemsClone,
    };
  };

  const updateItemQuantityState = (productItem) => {
    const productIndex = localOrderProcessState.value.items.findIndex((item) => item.id === productItem.id);
    localOrderProcessState.value = {
      ...localOrderProcessState.value,
      items: localOrderProcessState.value.items.map((item, index) =>
        index === productIndex ? { ...item, quantity: productItem.quantityState } : item
      ),
    };
  };

  const addProductItem = (productItem) => {
    const itemsClone = [...localOrderProcessState.value.items];
    itemsClone.push({
      ...productItem,
      quantityState: 0,
    });

    localOrderProcessState.value = {
      ...localOrderProcessState.value,
      items: itemsClone,
    };
  };

  const removeProductItem = (productItem) => {
    const productIndex = localOrderProcessState.value.items.findIndex((item) => item.id === productItem.id);
    localOrderProcessState.value = {
      ...localOrderProcessState.value,
      items: localOrderProcessState.value.items.filter((_, index) => index !== productIndex),
    };
  };

  const refreshLocalOrderProcessState = () => {
    localOrderProcessState.value = {
      ...localOrderProcessState.value,
      items: localOrderProcessState.value.items.map((item) => ({ ...item, quantityState: item.quantity })),
    };
  };
  return {
    ordersLoading,
    supplierId,
    updatedAt,
    arrivesAt,
    supplierName,
    openedByUser,
    orderNumber,
    openedAt,
    existingOrderNote,
    productsLoading,
    productsInOpenOrder,
    canInteractWithOrder,
    lastOrderProducts,
    productsFilteredBySearch,
    localOrderProcessState,
    productsInOpenOrderThatArentAvailable,
    addProductItem,
    removeProductItem,
    refetchOrderProcess,
    changeProductItemQuantity,
    refreshLocalOrderProcessState,
    updateItemQuantityState,
  };
}
