import i18n from '@/imports/startup/client/i18n';
import { TABLE_HEADERS } from './constants';

function getTranslationsKeysForHeaders(key) {
  return i18n.t(`orderProcessCart.${key}`);
}

export const catalogColumns = () => [
  {
    header: getTranslationsKeysForHeaders('product'),
    key: TABLE_HEADERS.NAME,
    with: '30%',
  },
  {
    header: getTranslationsKeysForHeaders('lastOrderPrice'),
    key: TABLE_HEADERS.LAST_PRICE,
    width: '20%',
  },
  {
    header: getTranslationsKeysForHeaders('quantity'),
    key: TABLE_HEADERS.QUANTITY_TO_ADD,
    width: '20%',
  },
  {
    header: getTranslationsKeysForHeaders('maxStockLevel'),
    key: TABLE_HEADERS.MAX_STOCK_LEVEL,
    width: '20%',
  },
  {
    header: getTranslationsKeysForHeaders('quantityInCart'),
    key: TABLE_HEADERS.QUANTITY,
    width: '10%',
  },
];

export const stocktakingColumns = () => [
  {
    header: getTranslationsKeysForHeaders('product'),
    key: TABLE_HEADERS.NAME,
    with: '25%',
  },
  {
    header: getTranslationsKeysForHeaders('lastOrderPrice'),
    key: TABLE_HEADERS.LAST_PRICE,
    width: '20%',
  },
  {
    header: getTranslationsKeysForHeaders('quantity'),
    key: TABLE_HEADERS.QUANTITY,
    width: '20%',
  },
  {
    header: getTranslationsKeysForHeaders('maxStockLevel'),
    key: TABLE_HEADERS.MAX_STOCK_LEVEL,
    width: '20%',
  },
];

export const orderProcessCartItems = () => [
  {
    header: getTranslationsKeysForHeaders('product'),
    key: TABLE_HEADERS.NAME,
    with: '25%',
  },
  {
    header: getTranslationsKeysForHeaders('lastOrderPrice'),
    key: TABLE_HEADERS.LAST_PRICE,
    width: '15%',
  },
  {
    header: getTranslationsKeysForHeaders('quantity'),
    key: TABLE_HEADERS.QUANTITY,
    width: '15%',
  },
  {
    header: getTranslationsKeysForHeaders('maxStockLevel'),
    key: TABLE_HEADERS.MAX_STOCK_LEVEL,
    width: '20%',
  },
  {
    header: getTranslationsKeysForHeaders('total'),
    key: TABLE_HEADERS.TOTAL,
    width: '20%',
  },
  {
    header: '',
    key: TABLE_HEADERS.ACTIONS,
    width: '5%',
  },
];
