import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core/compositions/notification';
import { getCurrentInstance } from 'vue';
import { useTranslate } from '@/modules/core/compositions/translate';
import { useMutationQueue } from './useMutationQueue';

export const useDeleteOrderItem = (updatedAt) => {
  const { $message } = getCurrentInstance().proxy;
  const $t = useTranslate();
  const { error } = useNotification();

  const { mutate, loading, onError, onDone } = useMutation(DELETE_ORDER_PROCESS_ITEM);

  onError((err) => {
    const errorBody = err?.graphQLErrors[0]?.extensions?.response?.body;
    if (errorBody && errorBody.details && errorBody.details.outdated === true) {
      $message.error({ message: $t('orderProcessCart.orderNotUpToDate'), duration: 5000 });
    } else {
      error();
      console.error('orderProcessCart - usePatchOrderProcessItems', err);
    }
  });

  return {
    deleteOrderItem: useMutationQueue(updatedAt, mutate, loading),
    onDone,
    loading,
  };
};

const DELETE_ORDER_PROCESS_ITEM = gql`
  mutation deleteOrderProcessItem($orderId: ID, $itemId: ID, $updatedAt: Timestamp) {
    deleteOrderProcessItem(orderId: $orderId, itemId: $itemId, updatedAt: $updatedAt) {
      orderId
      itemId
      updatedAt
    }
  }
`;
