import { ref, watch } from 'vue';

export const useMutationQueue = (updatedAt, mutate, loading) => {
  const queue = ref([]);

  const mutateToCall = (vars) => {
    queue.value.push(vars);
    queue.value = [...queue.value];
  };

  const executeAndPop = () => {
    if (queue.value.length > 0 && !loading.value) {
      const vars = queue.value.pop();
      mutate({ ...vars, updatedAt: updatedAt.value });
    }
  };

  watch(queue, executeAndPop);
  watch(loading, executeAndPop);

  return mutateToCall;
};
