import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useCatalog({ supplierId, currentTenant }) {
  const { error } = useNotification();

  const variables = computed(() => ({
    businessId: supplierId.value,
    exposedToBusiness: currentTenant.value?.id,
  }));

  const { result, loading, onError, refetch, onResult } = useQuery(CATALOG_QUERY, variables);
  const catalogConnection = computed(() => (result.value ? result.value.catalogs : { nodes: [], totalCount: 0 }));

  const totalCount = computed(() => catalogConnection.value.totalCount);

  const catalogs = computed(() => catalogConnection.value.nodes);

  onError((err) => {
    console.error('useCatalog', err);
    error();
  });

  return {
    catalogs,
    refetch,
    loading,
    totalCount,
    onResult,
  };
}

export const CATALOG_QUERY = gql`
  query catalogForSupplierManagement($businessId: ID, $exposedToBusiness: ID, $first: Int, $after: Int) {
    catalogs(businessId: $businessId, exposedToBusiness: $exposedToBusiness, first: $first, after: $after) {
      nodes {
        products {
          id
          name
          sku
          bom {
            uom
            quantity
            orderUnit
            billingUnit
            bom {
              uom
              quantity
              orderUnit
              billingUnit
              bom {
                uom
                quantity
                orderUnit
                billingUnit
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;
