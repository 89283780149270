import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification } from '@/modules/core/compositions/notification';

export const useGetOrdersWithLimit = (variables) => {
  const { error } = useNotification();
  const { result, loading, onError } = useQuery(
    GET_ORDERS_WITH_LIMIT,
    variables,
    () => ({
      enabled: !!variables.value.businessId && !!variables.value.supplierId,
    }),
    () => ({ fetchPolicy: 'cache-first' })
  );

  onError((err) => {
    console.error('useGetOrdersWithLimit', err);
    error();
  });

  const orders = computed(() => (result.value ? result?.value.nodes ?? [{ products: [] }] : [{ products: [] }]));

  return {
    orders,
    loading,
  };
};

const GET_ORDERS_WITH_LIMIT = gql`
  query orders($businessId: ID, $supplierId: ID, $limit: Int) {
    orders(businessId: $businessId, supplierId: $supplierId, limit: $limit) {
      nodes {
        id
        businessId
        customerId
        supplierId
        products {
          id
          productId
          price
          netPrice
          totalNetAmount
          totalAmount
          type
          product {
            id
            name
            sku
          }
        }
      }
    }
  }
`;
