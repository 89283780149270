import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { getCurrentInstance } from 'vue';
import { useNotification } from '@/modules/core/compositions/notification';
import { useTranslate } from '@/modules/core/compositions/translate';

export const usePatchOrderProcessItems = () => {
  const { $message } = getCurrentInstance().proxy;
  const $t = useTranslate();
  const { error } = useNotification();

  const { mutate, onError, loading, onDone } = useMutation(PATCH_ORDER_PROCESS_ITEMS);

  onError((err) => {
    const errorBody = err?.graphQLErrors[0]?.extensions?.response?.body;
    if (errorBody && errorBody.details && errorBody.details.outdated === true) {
      $message.error({ message: $t('orderProcessCart.orderNotUpToDate'), duration: 5000 });
    } else {
      error();
      console.error('orderProcessCart - usePatchOrderProcessItems', err);
    }
  });

  return {
    patchOrderProcessItems: mutate,
    onError,
    loading,
    onDone,
  };
};

const PATCH_ORDER_PROCESS_ITEMS = gql`
  mutation patchOrderProcessItems($orderId: ID!, $items: [OrderProcessItemsInput], $updatedAt: Timestamp) {
    patchOrderProcessItemsNew(orderId: $orderId, items: $items, updatedAt: $updatedAt) {
      items {
        id
        productId
        name
        sku
        quantity
      }
      updatedAt
    }
  }
`;
